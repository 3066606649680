




















import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import { IPopup } from './';

@Component({
  name: 'Popup'
})
class Popup extends Vue {
  @Prop({ required: true }) public config!: IPopup;

  @Watch('config') public onPropertyChanged() {
    if (this.config.isActive) {
      setTimeout(() => {
        this.config.isActive = false;
      }, 5000);
    }
  } public openClick = true;
  public created() {}
  public close() {
    if (this.config.onClose) {
      this.config.onClose();
    }

    this.config.isActive = false;
  }
}

export default Popup;
